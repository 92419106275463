import React, { useEffect } from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';


function LSOverlay() {
  const [ hideMsg, setHideMsg ] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHideMsg(Cookies.get('hideLsMsg') === '1');
    }, 5000);
  }, []);

  if (hideMsg) {
    return null;
  }

  return (
    <article className="p-2 bg-opacity-50 bg-black fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center" style={{ zIndex: 100 }}>
      <div className="p-10 md:max-w-4xl lg:max-w-6xl max-h-full rounded-lg bg-main-bg border-2 border-gray-400 flex flex-col">
        <p className="text-5xl mb-7">Dear Players,</p>
        <div className="overflow-y-auto flex-grow">
          <p className="text-4xl mb-5">As we informed you in the previous notice, 777Bay Casino is closed. However, you can still play all games for fun and website will continue to operate as an affiliate bay, providing you with special bonuses and exclusive deals to enrich your gaming journey.</p>
          <p className="text-4xl mb-5">Thank you for your continued loyalty.</p>
        </div>
        <div className="flex justify-center items-center flex-wrap pt-5">
          <span className="button big secondary mx-5 my-3" onClick={() => {
            Cookies.set('hideLsMsg', '1', { expires: 1 });
            setHideMsg(true);

            return false;
          }}>
            Close
          </span>
          <a className="button big primary mx-5 my-3" href="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3">
            Join Lucky Star
          </a>
        </div>
      </div>
    </article>
  );
}

export default LSOverlay;